import React from 'react';

import { Grid, Typography } from '@mui/material/index';

import metaService from '../services/metaService';
import { valueFormatter } from '../utils';
import UserAvatar from './Avatar';

import DataGridWrapper from './DataGridWrapper';
import UserForm from './UserForm';

const userGroups = metaService.getGroups();
const allDepts = metaService.getDepartments();
const allSites = metaService.getSites();

const API_ENDPOINT = 'admin/user';

export default function UserList() {
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.025 },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.2,
            renderCell: (params) => {
                return (
                    <Grid container>
                        <Grid item xs={3}>
                            <UserAvatar size={38} photo={params.row.photo} name={params.row.name} />
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1" component="p">
                                {params.row.name}
                            </Typography>
                            <Typography variant="caption" component="p">
                                {params.row.email}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }
        },
        {
            field: 'username',
            headerName: 'Username',
            flex: 0.1,
            editable: false
        },
        {
            field: 'employee_id',
            headerName: 'Employee ID',
            flex: 0.1,
            editable: false
        },
        {
            field: 'sites',
            headerName: 'Sites',
            sortable: false,
            width: 0.035,
            editable: false,
            valueOptions: allSites.map(({ id, name }) => ({ id: id, label: name })),
            valueGetter: (params) => valueFormatter(params.row.sites, allSites)
        },
        {
            field: 'departments',
            headerName: 'Departments',
            sortable: false,
            flex: 0.25,
            editable: false,
            valueOptions: allDepts.map(({ id, name }) => ({ id: id, label: name })),
            valueGetter: (params) => valueFormatter(params.row.departments, allDepts)
        },
        {
            field: 'groups',
            headerName: 'Groups',
            sortable: false,
            flex: 0.15,
            type: 'singleSelect',
            valueOptions: userGroups.map(({ id, name }) => ({ id: id, label: name })),
            valueGetter: (params) => valueFormatter(params.row.groups, userGroups)
        }
    ];

    return (
        <DataGridWrapper
            endpoint={API_ENDPOINT}
            columns={columns}
            entitySingle="User"
            entityMultiple="Users"
            Form={UserForm}
        />
    );
}
